import axios from 'axios'

const state = {
  levels: []
}

const getters = {
  levels: (state) => {
    return state.levels
  },


}

const mutations = {
  SET_LEVELS(state, payload){
    state.levels = payload
  },


}

const actions = {
  async fetchLevels({commit}){
    let pdts = await axios.get('levels')
    commit ("SET_LEVELS", pdts.data.data)
  },


}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
