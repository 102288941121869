import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";

import Notifications from "vue-notification";

import vSelect from "vue-select";

import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

Vue.component("v-select", vSelect);

import resize from "vue-element-resize-detector";

Vue.use(resize);

import "vue-select/src/scss/vue-select.scss";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "./assets/scss/app.scss";

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

import Ripple from 'vue-ripple-directive'

Vue.directive('ripple', Ripple);




// axios.defaults.baseURL = "http://ascension-admin.test/api/";
// axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://admin.ascensiongrouphaiti.com/api/";


import vueNumeralFilterInstaller from "vue-numeral-filter";

Vue.use(vueNumeralFilterInstaller, { locale: "en" });

import VueAwesomeSwiper from "vue-awesome-swiper";
import Vue2Filters from "vue2-filters";
import { VueMasonryPlugin } from "vue-masonry";
import InfiniteLoading from "vue-infinite-loading";
//import PayPal from "vue-paypal-checkout";
import VueLazyLoad from "vue-lazyload";
import ProductZoomer from "vue-product-zoomer";
import VueScrollTo from "vue-scrollto";

import "vue2-animate/dist/vue2-animate.min.css";
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";
import "swiper/css/swiper.css";

import VueDateFns from "vue-date-fns";
import locale from "date-fns/locale/fr";

Vue.use(VueDateFns, "dd MMMM yyyy", { locale });

Vue.use(Notifications);

import VueTree from "@ssthouse/vue-tree-chart";

Vue.component("vue-tree", VueTree);


import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";


Vue.use(VueLoading);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueAwesomeSwiper);
Vue.use(Vue2Filters);
Vue.use(VueMasonryPlugin);
Vue.use(InfiniteLoading);
//Vue.component("paypal-checkout", PayPal);
Vue.use(VueLazyLoad);
Vue.use(ProductZoomer);
Vue.use(VueScrollTo);

Vue.use(VueAxios, axios);

Vue.prototype.http = axios;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  created() {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        /*if (error.response.status === 401) {
          this.$store.dispatch("auth/logout");
        }*/
        return Promise.reject(error);
      }
    );
  },
  render: function(h) {
    return h(App);
  }
}).$mount("#app");
