<template>
  <div id="app">
    <router-view/>
    <notifications position="bottom right"/>
    <div id="debugCSSInfoBar"></div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";

  export default{
    name: 'app',

    components: {
    },

    computed: {
      ...mapGetters('setting', ['site_visible'])
    },

    mounted() {
      this.$store.dispatch('setting/fetchSettings')
    }
  }

</script>

<style lang="scss">

</style>
