import axios from "axios";

import _ from 'lodash'

const state = {
  user: sessionStorage.getItem("user"),
};

const getters = {
  user: (state) => (state.user ? JSON.parse(state.user) : null),
  isLogged: (state) => !!state.user,
};

const mutations = {
  SET_USER_DATA(state, userData) {
    sessionStorage.setItem("user", JSON.stringify(userData));
    state.user = sessionStorage.getItem("user");
  },

  SET_USER_TOKEN(state, userToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;
  },

  SET_TEMP_USER_DATA(data) {
    sessionStorage.setItem("userTemp", JSON.stringify(data));
  },

  CLEAR_USER_DATA(state) {
    sessionStorage.removeItem("user");
    state.user = sessionStorage.getItem("user");
    axios.defaults.headers.common.Authorization = null;
  },
};

const actions = {
  login({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("login", credentials)
        .then(({ data }) => {
          if (data.success && data.data.user.active && _.includes(['granted', 'denied', 'inactive'], data.data.user.situation)) {
            // dispatch('cart/fetchCart', data.data.user.cart_id, {root:true})
            commit("SET_USER_DATA", data.data.user);
            commit("SET_USER_TOKEN", data.data.access_token);
          }else{
            sessionStorage.setItem("userTemp", JSON.stringify(data.data));
          }
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  payment({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("customer/payment/moncash/" + id)
        .then(({ data }) => {
          commit("SET_TEMP_USER_DATA", sessionStorage.getItem("userTemp"));
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  paymentSucess({ commit }) {
    const data = sessionStorage.getItem("userTemp");
    if(data!=undefined && data!=null){
      commit("SET_USER_DATA", data);
      sessionStorage.removeItem("userTemp");
    }
  },
  logout({ commit }) {
    commit("CLEAR_USER_DATA");
    return true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
