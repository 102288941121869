import axios from 'axios'

const state = {
  settings: ''
}

const getters = {
  title: (state) => state.settings.site_name,
  addresse: (state) => state.settings.addresse,
  telephone: (state) => state.settings.telephone,
  site_visible: (state) => state.settings.site_visible,
  contact_email : (state) => state.settings.contact_email,
  intro : (state) => state.settings.intro,
  legendCustomer : (state) => state.settings.legends,
  time_price : (state) => state.settings.access_time_price,
  time_min_day : (state) => state.settings.access_time_min_day
}

const mutations = {
  SET_settingS(state, payload){
    state.settings = payload
  },


}

const actions = {
  async fetchSettings({commit}){
    let pdts = await axios.get('settings')
    commit ("SET_settingS", pdts.data.data)
  },


}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
