import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/payment-success",
    name: "PaymentSuccess",
    meta: {
      auth: true
    },
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "./../views/page/payment-success.vue"
      );
    },
  },
  {
    path: "/opportunite",
    name: "Opportunite",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "./../views/page/opportunite.vue"
      );
    },
  },
  {
    path: "/produits",
    name: "Produits",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "./../views/products/index.vue"
      );
    },
  },
  {
    path: "/produit/:slug",
    name: "Detail_Produits",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "productDetails" */ "./../views/products/productDetails.vue"
      );
    },
  },
  {
    path: "/compagnie",
    name: "Compagnie",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "compagnie" */ "./../views/page/compagnie.vue"
      );
    },
  },
  {
    path: "/aide",
    name: "Aide",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "faq" */ "./../views/page/faq.vue");
    },
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "login" */ "./../views/page/account/login.vue"
      );
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "forgotPassword" */ "./../views/page/account/forgetPassword.vue"
      );
    },
  },

  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "forgotPassword" */ "./../views/page/account/resetPassword.vue"
      );
    },
  },
  {
    path: "/register/:customer?",
    name: "Register",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "register" */ "./../views/page/account/register.vue"
      );
    },
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "./../views/page/account/contact.vue"
      );
    },
  },
  {
    path: "/evenement",
    name: "Evenement",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "./../views/page/event.vue"
      );
    },
  },
  {
    path: "/evenement/:id",
    name: "EvenementDetails",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "./../views/page/eventDetails.vue"
      );
    },
  },
  {
    path: "/checkout",
    name: "CheckOut",
    meta: {
      auth: true,
    },
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "./../views/page/account/checkout.vue"
      );
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      auth: true,
    },
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "./../views/page/account/dashboard.vue"
      );
    },
    children: [
      {
        path: "",
        component: () =>
          import("./../views/page/account/dashComponent/index.vue"),
      },
      {
        path: "/dashboard/panier",
        component: () =>
          import("./../views/page/account/dashComponent/cart.vue"),
      },
      {
        path: "/dashboard/enregistrement",
        component: () =>
          import("./../views/page/account/dashComponent/register-godson.vue"),
      },
      {
        path: "/dashboard/networking",
        component: () =>
          import("./../views/page/account/dashComponent/networkingWrapper.vue"),
      },
      {
        path: "/dashboard/report",
        component: () =>
          import("./../views/page/account/dashComponent/report.vue"),
      },
      {
        path: "/dashboard/messages",
        component: () =>
          import("./../views/page/account/dashComponent/messages.vue"),
      },
      {
        path: "/dashboard/documents",
        component: () =>
          import("./../views/page/account/dashComponent/documents.vue"),
      },
      {
        path: "/dashboard/setting",
        component: () =>
          import("./../views/page/account/dashComponent/setting.vue"),
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];

// const routes = [
//   {
//     path: "/",
//     name: "Home",
//     component: Home
//   },
//   {
//     path: "/#/opportunite",
//     name: "Opportunite",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: function() {
//       return import(/* webpackChunkName: "about" */ "../views/page/opportunite.vue");
//     }
//   },
//   {
//     path: "/#/produits",
//     name: "Produits",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: function() {
//       return import(/* webpackChunkName: "about" */ "../views/products/index.vue");
//     }
//   },
//   {
//     path: "/#/produit/:id",
//     name: "Detail_Produits",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: function() {
//       return import(/* webpackChunkName: "about" */ "../views/products/productDetails.vue");
//     }
//   },
//   {
//     path: "/#/compagnie",
//     name: "Compagnie",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: function() {
//       return import(/* webpackChunkName: "about" */ "../views/page/compagnie.vue");
//     }
//   },
//   {
//     path: "/#/aide",
//     name: "Aide",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: function() {
//       return import(/* webpackChunkName: "about" */ "../views/page/faq.vue");
//     }
//   },
//   {
//     path: "/#/login",
//     name: "Login",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: function() {
//       return import(/* webpackChunkName: "about" */ "../views/page/account/login.vue");
//     }
//   },
//   {
//     path: "/#/register",
//     name: "Register",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: function() {
//       return import(/* webpackChunkName: "about" */ "../views/page/account/register.vue");
//     }
//   },
//   {
//     path: "/#/contact",
//     name: "Contact",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: function() {
//       return import(/* webpackChunkName: "about" */ "../views/page/account/contact.vue");
//
//     }
//   },
//   {
//     path: "/#/evenement",
//     name: "Evenement",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: function() {
//       return import(/* webpackChunkName: "about" */ "../views/page/event.vue");
//     }
//   },
//   {
//     path: "/#/evenement/:id",
//     name: "EvenementDetails",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: function() {
//       return import(/* webpackChunkName: "about" */ "../views/page/eventDetails.vue");
//     }
//   },
//   {
//     path: "/#/checkout",
//     name: "CheckOut",
//     meta: {
//       auth: true
//     },
//     component: function() {
//       return import(/* webpackChunkName: "about" */ "../views/page/account/checkout.vue");
//     }
//   },
//   {
//     path: "/#/dashboard",
//     meta: {
//       auth: true
//     },
//     component: function() {
//       return import(/* webpackChunkName: "about" */ "../views/page/account/dashboard.vue");
//     },
//     children: [
//       { path: "", component: () => import("./../views/page/account/dashComponent/index.vue") },
//       { path: "/#/dashboard/panier", component: () => import("./../views/page/account/dashComponent/cart.vue") },
//       {
//         path: "/#/dashboard/enregistrement",
//         component: () => import("./../views/page/account/dashComponent/register-godson.vue")
//       },
//       {
//         path: "/#/dashboard/networking",
//         component: () => import("./../views/page/account/dashComponent/networking.vue")
//       },
//       {
//         path: "/#/dashboard/report",
//         component: () => import("./../views/page/account/dashComponent/report.vue")
//       },
//       {
//         path: "/#/dashboard/messages",
//         component: () => import("./../views/page/account/dashComponent/messages.vue")
//       },
//       {
//         path: "/#/dashboard/documents",
//         component: () => import("./../views/page/account/dashComponent/documents.vue")
//       },
//       {
//         path: "/#/dashboard/setting",
//         component: () => import("./../views/page/account/dashComponent/setting.vue")
//       }
//     ]
//   }
// ];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.auth);
  const loggedIn = sessionStorage.getItem("user");
  if (requiresAuth && !loggedIn) {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else if (requiresAuth && !loggedIn) {
    next();
  } else {
    next();
  }
});

router.onError((error) => {
  console.error(error);
  // Vue.prototype.$log.error('Failure Reason: ', error.message, error);
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    // Vue.prototype.$log.error('Reloading Window 1');
    window.location.reload();
  } else if (/Loading.*chunk.*failed./i.test(error.message)) {
    // Vue.prototype.$log.error('Reloading Window 2');
    window.location.reload();
  }
});

export default router;
