import axios from 'axios'

const state = {
  attributes: []
}

const getters = {
  attributes: (state) => {
    return state.attributes
  },


}

const mutations = {
  SET_ATTRIBUTES(state, payload){
    state.attributes = payload
  },


}

const actions = {
  async fetchAttributes({commit}){
    let pdts = await axios.get('attributes')
    commit ("SET_ATTRIBUTES", pdts.data.data)
  },


}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
