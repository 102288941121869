import axios from 'axios'

const state = {
  categories: []
}

const getters = {
  categories: (state) => {
    return state.categories
  },


}

const mutations = {
  SET_CATEGORIES(state, payload){
    state.categories = payload
  },


}

const actions = {
  async fetchCategories({commit}){
    let pdts = await axios.get('categories')
    commit ("SET_CATEGORIES", pdts.data.data)
    console.log("PDTS", pdts.data.data.categories)
  },


}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
