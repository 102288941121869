<template>
  <div class="home">
    <ascension/>
  </div>
</template>

<script>

const Ascension = () => import('./ascension/Ascension.vue')


export default {
  name: "Home",

  components: {
    Ascension
  },


};
</script>
