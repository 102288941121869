import axios from "axios";

const state = {
  orders: [],
  teamOrders: [],
  allOrders: [],
};

const getters = {
  orders: (state) => {
    return state.orders;
  },

  teamOrders: (state) => {
    return state.teamOrders;
  },

  allOrders: (state) => {
    return state.allOrders;
  },
};

const mutations = {
  SET_ORDERS(state, payload) {
    state.orders = payload.orders;
    state.teamOrders = payload.teamOrders;
    state.allOrders = payload.allOrders;
    console.log('state.orders', state.orders)
  },
  SET_ORDERS_EMPTY(state) {
    console.log('state.orders', state.orders)
  },
};

const actions = {
  async fetchOrders({ commit }, customerId) {
    let pdts = await axios.get("orders/" + customerId);
    commit("SET_ORDERS", pdts.data.data);
    console.log("fetchOrders", pdts.data.data);
  },

  async payment({ commit }, payload) {
    let pdts = await axios.post("pay", payload);
    commit("SET_ORDERS_EMPTY");
    return pdts.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
