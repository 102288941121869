import axios from 'axios'

const state = {
  cities: []
}

const getters = {
  cities: (state) => {
    return state.cities
  },


}

const mutations = {
  SET_CITIES(state, payload){
    state.cities = payload
  },


}

const actions = {
  async fetchCities({commit}){
    let pdts = await axios.get('boundaries')
    commit ("SET_CITIES", pdts.data.data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
