import axios from "axios";

const state = {
  user: sessionStorage.getItem("user"),
};

const getters = {
  user: (state) => (state.user ? JSON.parse(state.user) : null),
  isLogged: (state) => !!state.user,
};

const mutations = {
  SET_USER_DATA(state, userData) {
    sessionStorage.setItem("user", JSON.stringify(userData.user));
    state.user = sessionStorage.getItem("user");
    axios.defaults.headers.common.Authorization = `Bearer ${userData.access_token}`;
  },

  SET_TEMP_USER_DATA(data) {
    sessionStorage.setItem("userTemp", JSON.stringify(data));
  },

  CLEAR_USER_DATA(state) {
    sessionStorage.removeItem("user");
    state.user = sessionStorage.getItem("user");
    axios.defaults.headers.common.Authorization = null;
  },
};

const actions = {
  pay(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/pay", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
