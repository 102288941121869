import axios from 'axios'

const state = {
  evenements: [],
  evenement: ''
}

const getters = {
  evenements: (state) => {
    return state.evenements.filter(item => !item.isPast)
  },

  newEvents: (state) => {
    return state.evenements.filter(item => item.isNew)
  },

  oldEvents: (state) => {
    return state.evenements.filter(item => item.isPast)
  },

  evenement: (state) => {
    return state.evenement
  }
}

const mutations = {
  SET_EVENEMENTS(state, payload){
    state.evenements = payload
  },

  SET_EVENEMENT(state, payload){
    state.evenement = payload
  },


}

const actions = {
  async fetchEvenements({commit}){
    let pdts = await axios.get('evenements')
    commit ("SET_EVENEMENTS", pdts.data.data)
  },

  async getEventDetails({commit}, id){
    let pdts = await axios.get('evenements/'+id)
    commit ("SET_EVENEMENT", pdts.data.data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
