import axios from "axios";



const state = {
  customer: "",
  newCustomers: []
};

const getters = {
  customer: (state) => {
    return state.customer;
  },

  newCustomers: (state) => {
    return state.newCustomers;
  }
};

const mutations = {
  SET_CUSTOMER(state, payload) {
    state.customer = payload;
  },

  SET_NEW_CUSTOMERS(state, payload) {
    state.newCustomers = payload;
  }
};

const actions = {
  async fetchCustomers({ commit }, payload) {
    let pdts = await axios.get(`customers/${payload.customer}/${payload.dashtab}`);
    commit("SET_CUSTOMER", pdts.data.data);
  },

  async fetchNewCustomer({ commit }, customerId) {
    let pdts = await axios.get("customer/active/" + customerId);
    commit("SET_NEW_CUSTOMERS", pdts.data.data);
  },

  async payment(context, customerId) {
    let pdts = await axios.get("customers/" + customerId + "/pay/moncash");
    return pdts.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
