import axios from "axios";

const state = {
    wallet: '',
    walletArchived: '',
    walletAll: '',
    totalIncome: ''
}

const getters = {
    wallet: (state) => {
        return state.wallet
    },
    walletArchived: (state) => {
        return state.walletArchived
    },
    walletAll: (state) => {
        return state.walletAll
    },
    totalIncome: (state) => {
        return state.totalIncome
    },


}

const mutations = {
    SET_WALLET(state, payload){
        state.wallet = payload
    },
    SET_WALLET_ARCHIVED(state, payload){
        state.walletArchived = payload
        console.log('SET_WALLET_ARCHIVED',state.walletArchived);
    },
    SET_WALLET_All(state, payload){
        state.walletAll = payload
        console.log('SET_WALLET_All',state.walletAll);
    },
    SET_TOTAL_INCOME(state, payload){
        state.totalIncome = payload
    },
}

const actions = {
    async fetchWallets({commit}, customerId){
        let pdts = await axios.get('wallets/'+customerId)
        if(pdts.data.success) {
            commit("SET_WALLET", pdts.data.data)
        }else{
            commit("SET_WALLET", [])
        }
    },
    async fetchWalletsArchived({commit}, customerId){
        let pdts = await axios.get('wallets/'+customerId+"?paid=1")
        if(pdts.data.success) {
            commit ("SET_WALLET_ARCHIVED", pdts.data.data)
        }else{
            commit("SET_WALLET_ARCHIVED", [])
        }
    },
    async fetchWalletsAll({commit}, customerId){
        let pdts = await axios.get('wallets/'+customerId+"?all=1")
        if(pdts.data.success) {
            commit ("SET_WALLET_All", pdts.data.data)
        }else{
            commit("SET_WALLET_All", [])
        }
    },
    async fetchTotalIncome({commit}, customerId){
        let pdts = await axios.get('wallets/'+customerId+"?total=1")
        if(pdts.data.success) {
            commit ("SET_TOTAL_INCOME", pdts.data.data)
        }else{
            commit("SET_TOTAL_INCOME", [])
        }
    }


}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
