import Vue from "vue";

const state = {
  cart: localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : []
};

const getters = {
  cartItems: (state) => {
    console.log('state.cart',state.cart)
    return state.cart;
  },

  cartTotalAmount: (state) => {
    return state.cart.reduce((total, product) => {
      return total + product.price * product.quantity;
    }, 0);
  },

  cartItemTaxeAmount: (state) => {
    let array = {}
    state.cart.map((product) => {
      if(product.feeRule!=null){
        let val = product.feeRule.reduce((tax, fee)=>{
          if(fee.status && fee.type=='PERCENT') {
            tax += parseFloat(fee.value + "") * parseFloat(product.price+"")/100
          }else if(fee.status && fee.type!='PERCENT'){
            tax += parseFloat(fee.value+"")
          }
          return tax
        }, 0.0);
        array[product.id] = val
      }
      else{
        array[product.id] =  0.0;
      }
    });
    return array
  },

  cartTotalPoint: (state) => {
    return state.cart.reduce((total, product) => {
      return total + product.point * product.quantity;
    }, 0);
  },

  cartTotalCashBack: (state) => {
    return state.cart.reduce((total, product) => {
      return (total + ((product.discount_type === "PERCENT" ? product.price * (product.discount / 100) : product.discount) *
          product.quantity)
      );
    }, 0);
  },

  cartTaxTotal: (state) => {
    return state.cart.reduce((total, product) => {
      return total + product.quantity * (!product.feeRule
          ? 0
          : (product.feeRule.fees || []).reduce((totalFee, cum) => {
            return totalFee + parseFloat((cum.type === "PERCENT" ? product.price * cum.value / 100 : cum.value));
          }, 0)
      );
    }, 0);
  }
};

const mutations = {
  ADD_TO_CART: (state, payload) => {
    const product = payload;
    const cart = state.cart;
    const cartItems = cart.find((item) => item.id === payload.id);
    const qty = payload.quantity ? payload.quantity : 1;
    if (cartItems) {
      cartItems.quantity += qty;
    } else {
      cart.push({
        ...product,
        quantity: qty
      });
      localStorage.setItem("cart", JSON.stringify(cart));
      state.cart = JSON.parse(localStorage.getItem("cart"));
    }
    product.stock--;
  },

  UPDATE_CART_QUANTITY: (state, payload) => {
    // Calculate Product stock Counts
    const cart = state.cart;

    function calculateStockCounts(product, quantity) {
      const qty = product.quantity + quantity;
      const stock = product.stock;
      if (stock < qty) {
        return false;
      }
      return true;
    }

    cart.find((items, index) => {
      if (items.id === payload.product.id) {
        const qty = cart[index].quantity + payload.qty;
        const stock = calculateStockCounts(cart[index], payload.qty);
        if (qty !== 0 && stock) {
          cart[index].quantity = qty;
        } else {
          cart.push({
            ...payload,
            quantity: qty
          });
          localStorage.setItem("cart", JSON.stringify(cart));
          state.cart = JSON.parse(localStorage.getItem("cart"));
        }
        return true;
      }
    });
  },

  REMOVE_CART_ITEM: (state, payload) => {
    const cart = state.cart;
    const index = cart.indexOf(payload);
    cart.splice(index, 1);
    localStorage.setItem("cart", JSON.stringify(cart));
    state.cart = JSON.parse(localStorage.getItem("cart"));
  },

  CLEAR_CART: (state) => {
    localStorage.removeItem("cart");
    state.cart = [];
  }
};

const actions = {
  determineDiscount: (productPrice, discount, discoutnType) => {
    let discountValue = 0;
    if (discoutnType === "PERCENT") {
      discountValue = productPrice * (discount / 100);
    } else {
      discountValue = discount;
    }
    return discountValue;
  },

  addToCart: (context, payload) => {
    context.commit("ADD_TO_CART", payload);
    Vue.notify({
      type: "success",
      title: "Succès",
      text: payload.name+" ajouté (e) avec succès"
    });
  },

  updateCartQuantity: (context, payload) => {
    context.commit("UPDATE_CART_QUANTITY", payload);
    Vue.notify({
      type: "success",
      title: "Succès",
      text: payload.product.name+" modifié (e) avec succès"
    });
  },

  removeCartItem: (context, payload) => {
    context.commit("REMOVE_CART_ITEM", payload);
    Vue.notify({
      type: "warning",
      title: "Succès",
      text: payload.name+" retiré (e) avec succès"
    });
  },

  clearCart: (context) => {
    context.commit("CLEAR_CART");
    Vue.notify({
      type: "warning",
      title: "Succes",
      text: "Panier vide"
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
