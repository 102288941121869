import Vue from "vue";
import Vuex from "vuex";


import auth from "./Auth";
import cart from "./Cart";
import products from "./Product";
import category from "./Category";
import attribute from "./Attribute";
import order from "./Order";
import wallet from "./Wallet";
import customer from "./Customer";
import message from "./Message";
import evenements from "./Evenement"
import setting from "./Setting"
import level from "./Level"
import city from "./City"
import payment from "./Pay"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    cart,
    products,
    category,
    attribute,
    order,
    wallet,
    customer,
    message,
    evenements,
    setting,
    level,
    city,
    payment
  }
});
