import axios from "axios";
import _ from 'lodash'

const state = {
  messengers: [],
  messenger: ''
};

const getters = {
  messengers: (state) => {
    return _.orderBy(state.messengers, ['read_at', 'create_at'], ['desc', 'desc'] );
  },

  unReadCount: (state) => _.filter(state.messengers, (o) => !o.read_at).length,

  newMessengers: (state) =>{
    return state.messengers.newMessage;
  }


};

const mutations = {
  SET_MESSENGERS(state, payload) {
    state.messengers = payload;
  },

  SET_MESSENGER(state, payload){
    const messengerIndex = state.messengers.findIndex(p => p._id == payload._id);
    Object.assign(state.messengers[messengerIndex], payload);
  }
};

const actions = {
  async fetchMessengers({ commit }, user_id) {
    let pdts = await axios.get("messages", {
      params: {
        id: user_id
      }
    });
    commit("SET_MESSENGERS", pdts.data.data);
  },

  async markMessageAsRead({commit}, d){
      let pdts = await axios.get("messages/"+d.thread.id);
      commit("SET_MESSENGER", pdts.data.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
