import axios from "axios";

const state = {
  productslist: [],

  products: [],
  pagination : null,
  productDetails: null,

  wishlist: [],
  compare: [],
  currency: {
    curr: 'usd',
    symbol: '$'
  },
  order: [],
  locale: 'en',
  searchProduct: [],

//  ------------------------------
  tagItems: [],
  filteredProduct: [],
  paginate: 12,
  pages: [],
  priceArray: []
//  -----------------------------
}

const getters = {
  products(state){
    return state.products
  },

  productList(state){
    return state.productslist
  },

  productDetails(state){
    return state.productDetails
  },

  pagination(state){
    console.log("setPagination", state.pagination)
    return state.pagination
  },

  filterQuery(state){
    return state.filterQuery
  },

  getcollectionProduct: (state) => {
    return collection => state.products.filter((product) => {
      return collection === product.collection
    })
  },
  getProductById: (state) => {
    return id => state.products.find((product) => {
      return product.id === +id
    })
  },
  compareItems: (state) => {
    return state.compare
  },
  wishlistItems: (state) => {
    return state.wishlist
  },
  changeCurrency: (state) => {
    if (state.currency.curr === 'eur') {
      state.currency.curr = 0.90
      return state.currency
    } else if (state.currency.curr === 'inr') {
      state.currency.curr = 70.93
      return state.currency
    } else if (state.currency.curr === 'gbp') {
      state.currency.curr = 0.78
      return state.currency
    } else if (state.currency.curr === 'usd') {
      state.currency.curr = 1
      return state.currency
    }
  },
  getOrder: (state) => {
    return state.order
  },

//  ----------------------------------------------
  filterbyCategory: (state) => {
    const category = [...new Set(state.products.map(product => product.type))]
    return category
  },
//  ----------------------------------------------
}

const mutations = {
  SET_PRODUCTS(state, products){
    state.products = JSON.parse(JSON.stringify(products))
    state.productslist = products
  },

  SET_PRODUCT(state, product){
    console.log("PDETAILS", product)
    state.productDetails = product
  },

  SET_PAGINATION(state, pagination){
    state.pagination = JSON.parse(JSON.stringify(pagination))
  },
  
  changeCurrency: (state, payload) => {
    state.currency = payload
  },
  addToWishlist: (state, payload) => {
    const product = state.products.find(item => item.id === payload.id)
    const wishlistItems = state.wishlist.find(item => item.id === payload.id)
    if (wishlistItems) {
      console.log(wishlistItems)
    } else {
      state.wishlist.push({
        ...product
      })
    }
  },
  removeWishlistItem: (state, payload) => {
    const index = state.wishlist.indexOf(payload)
    state.wishlist.splice(index, 1)
  },
  addToCompare: (state, payload) => {
    const product = state.products.find(item => item.id === payload.id)
    const compareItems = state.compare.find(item => item.id === payload.id)
    if (compareItems) {
      console.log(compareItems)
    } else {
      state.compare.push({
        ...product
      })
    }
  },
  removeCompareItem: (state, payload) => {
    const index = state.compare.indexOf(payload)
    state.compare.splice(index, 1)
  },
  searchProduct: (state, payload) => {
    payload = payload.toLowerCase()
    state.searchProduct = []
    if (payload.length) {
      state.products.filter((product) => {
        if (product.title.toLowerCase().includes(payload)) {
          state.searchProduct.push(product)
        }
      })
    }
  },
  createOrder: (state, payload) => {
    state.order = payload
  },

//  ---------------------------------------------------
  getCategoryFilter: (state, payload) => {
    state.filteredProduct = []
    state.tagItems = []
    state.products.filter((product) => {
      if (payload === product.type) {
        state.filteredProduct.push(product)
        state.priceArray = state.filteredProduct
      }
      if (payload === 'all' || payload === undefined) {
        state.filteredProduct.push(product)
        state.priceArray = state.filteredProduct
      }
    })
  },
  priceFilter: (state, payload) => {
    state.filteredProduct = []
    state.priceArray.find((product) => {
      if (product.price >= payload[0] && product.price <= payload[1]) {
        state.filteredProduct.push(product)
      }
    })
  },
  setTags: (state, payload) => {
    state.tagItems = payload
  },
  sortProducts: (state, payload) => {
    if (payload === 'a-z') {
      state.products.sort(function (a, b) {
        if (a.name < b.name) {
          return -1
        } else if (a.name > b.name) {
          return 1
        }
        return 0
      })
    } else if (payload === 'z-a') {
      state.products.sort(function (a, b) {
        if (a.name > b.name) {
          return -1
        } else if (a.name < b.name) {
          return 1
        }
        return 0
      })
    } else if (payload === 'low') {
      state.products.sort(function (a, b) {
        if (a.price < b.price) {
          return -1
        } else if (a.price > b.price) {
          return 1
        }
        return 0
      })
    } else if (payload === 'high') {
      state.products.sort(function (a, b) {
        if (a.price > b.price) {
          return -1
        } else if (a.price < b.price) {
          return 1
        }
        return 0
      })
    }
  }
//  --------------------------------------------------
}

const actions = {
  fetchProducts({ commit }, query) {
    return new Promise((resolve, reject) => {
      axios
        .get(`products`,{
          params: {
            page: 1,
            categories: [],
            name: null,
            sort: "all",
            ...query
          }
        })
          .then(data => {
            if(data.data.success){
              commit ("SET_PRODUCTS", data.data.data.products)
              commit("SET_PAGINATION", data.data.data.pagination)
            }
            resolve(data.data.data.products)
          })
          .catch(err => {
            reject(err)
          })
    })
  },

  getDetailProduct({ commit }, id){
    return new Promise((resolve, reject) => {
      axios
          .get("products/"+id)
          .then(data => {
            if(data.data.success){
              commit ("SET_PRODUCT", data.data.data)
            }
            resolve(data.data.data)
          })
          .catch(err => {
            reject(err)
          })
    })
  },

  changeCurrency: (context, payload) => {
    context.commit('changeCurrency', payload)
  },
  addToWishlist: (context, payload) => {
    context.commit('addToWishlist', payload)
  },
  removeWishlistItem: (context, payload) => {
    context.commit('removeWishlistItem', payload)
  },
  addToCompare: (context, payload) => {
    context.commit('addToCompare', payload)
  },
  removeCompareItem: (context, payload) => {
    context.commit('removeCompareItem', payload)
  },
  createOrder: (context, payload) => {
    context.commit('createOrder', payload)
  },

//  ------------------------------------------------
  getpaginate: (context, payload) => {
    context.commit('getpaginate', payload)
  },
  getCategoryFilter: (context, payload) => {
    context.commit('getCategoryFilter', payload)
  },
  priceFilter: (context, payload) => {
    context.commit('priceFilter', payload)
  },
  setTags: (context, payload) => {
    context.commit('setTags', payload)
  },
  sortProducts: (context, payload) => {
    context.commit('sortProducts', payload)
  }
//  -----------------------------------------------
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
